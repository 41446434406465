import axios from "axios";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { axiosInstance, titleCase } from "../util/util";
import { SiSpigotmc } from "react-icons/si";
import { FaRegPaperPlane, FaStar } from "react-icons/fa";
import { LuScroll } from "react-icons/lu";
import { GrBucket } from "react-icons/gr";
import { TbCube } from "react-icons/tb";
import Loading from '../assets/loading.gif';
import {
  FaSkullCrossbones, // CURSED
  FaPaintBrush, // DECORATION
  FaCoins, // ECONOMY
  FaTools, // EQUIPMENT
  FaDrumstickBite, // FOOD
  FaGamepad, // GAME_MECHANICS
  FaBook, // LIBRARY
  FaMagic, // MAGIC
  FaClipboardList, // MANAGEMENT
  FaDice, // MINIGAME
  FaSpider, // MOBS
  FaTachometerAlt, // OPTIMIZATION
  FaUsers, // SOCIAL
  FaBoxes, // STORAGE
  FaLaptopCode, // TECHNOLOGY
  FaTruck, // TRANSPORTATION
  FaWrench, // UTILITY
  FaGlobe, // WORLD_GENERATION
} from "react-icons/fa";

const MODRINTH_URL = "https://modrinth.com/plugin";
const MODRINTH_API = "https://lode.gg/api/modrinth";
const LOADER_CLASS_NAME = "items-center justify-center flex flex-row mr-4 rounded-2xl text-[12px]";
const LOADERS = ["SPIGOT", "PURPUR", "PAPER", "BUKKIT"];
const CATEGORIES = ["ADVENTURE", "CURSED", "DECORATION", "ECONOMY", "EQUIPMENT", "GAME_MECHANICS", "LIBRARY", "MAGIC", "MANAGEMENT", "MINIGAME", "MOBS", "OPTIMIZATION", "SOCIAL", "STORAGE", "TECHNOLOGY", "TRANSPORTATION", "UTILTY", "WORLD_GENERATION"];

export default function Plugins() {
  const [search, setSearch] = useState("");
  const [pluginStatistics, setPluginStatistics] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [loaderFilter, setLoaderFilter] = useState([]);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/landing/plugins").then((res) => setPlugins(res?.data ?? [])).catch(() => null);
  }, []);

  useEffect(() => {
    const fetchPluginData = async () => {
      const pluginDataPromises = plugins.map(async (plugin) => {
        try {
          if (plugin.paid) return plugin;

          const pluginRes = await axios.get(`${MODRINTH_API}/${plugin.id}`);
          const { description, downloads, icon_url, title, wiki_url, categories, loaders } =
            pluginRes.data;

          const versionRes = await axios.get(
            `${MODRINTH_API}/${plugin.id}/version`
          );
          const versions = versionRes.data[0]?.game_versions || [];
          const version = versionRes.data[0]?.version_number || "Unknown";

          return {
            categories,
            loaders,
            versions,
            description,
            downloads,
            icon_url,
            title,
            version,
            id: plugin.id,
            wiki_url,
          };
        } catch (error) {
          console.error(`Error fetching data for plugin ${plugin.id}:`, error);
          return null;
        }
      });

      const pluginData = await Promise.all(pluginDataPromises);
      setPluginStatistics((prev) => [...prev, ...pluginData.filter(Boolean)]);
    };

    if (plugins.length > 0) fetchPluginData();
  }, [plugins]);

  function filterSerach(str) {
    return search.length === 0 ||
      str.id.toLowerCase().includes(search.toLowerCase()) ||
      str.title.toLowerCase().includes(search.toLowerCase()) ||
      (str.categories ?? []).find(c => c.toLowerCase().includes(search.toLowerCase())) ||
      (str.loaders ?? []).find(l => l.toLowerCase().includes(search.toLowerCase()));
  }

  function getCategory(category) {
    switch (category.toUpperCase()) {
      case "ADVENTURE":
        return <div className={`${LOADER_CLASS_NAME} text-[#61e730]`}>
          <FaStar size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "CURSED":
        return <div className={`${LOADER_CLASS_NAME} text-[#ff6363]`}>
          <FaSkullCrossbones size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "DECORATION":
        return <div className={`${LOADER_CLASS_NAME} text-[#6081e2]`}>
          <FaPaintBrush size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "ECONOMY":
        return <div className={`${LOADER_CLASS_NAME} text-[#22952e]`}>
          <FaCoins size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "EQUIPMENT":
        return <div className={`${LOADER_CLASS_NAME} text-[#fac042]`}>
          <FaTools size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "FOOD":
        return <div className={`${LOADER_CLASS_NAME} text-[#e6ee4c]`}>
          <FaDrumstickBite size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "GAME_MECHANICS":
        return <div className={`${LOADER_CLASS_NAME} text-[#53ea5a]`}>
          <FaGamepad size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "LIBRARY":
        return <div className={`${LOADER_CLASS_NAME} text-[#f8f377]`}>
          <FaBook size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "MAGIC":
        return <div className={`${LOADER_CLASS_NAME} text-[#df47f4]`}>
          <FaMagic size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "MANAGEMENT":
        return <div className={`${LOADER_CLASS_NAME} text-[#4485da]`}>
          <FaClipboardList size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "MINIGAME":
        return <div className={`${LOADER_CLASS_NAME} text-[#f67534]`}>
          <FaDice size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "MOBS":
        return <div className={`${LOADER_CLASS_NAME} text-[#ff2323]`}>
          <FaSpider size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "OPTIMIZATION":
        return <div className={`${LOADER_CLASS_NAME} text-[#c0eb5a]`}>
          <FaTachometerAlt size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "SOCIAL":
        return <div className={`${LOADER_CLASS_NAME} text-[#698aff]`}>
          <FaUsers size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "STORAGE":
        return <div className={`${LOADER_CLASS_NAME} text-[#f0c453]`}>
          <FaBoxes size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "TECHNOLOGY":
        return <div className={`${LOADER_CLASS_NAME} text-[#667aff]`}>
          <FaLaptopCode size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "TRANSPORTATION":
        return <div className={`${LOADER_CLASS_NAME} text-[#3eff88]`}>
          <FaTruck size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "UTILITY":
        return <div className={`${LOADER_CLASS_NAME} text-[#faf4f4]`}>
          <FaWrench size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      case "WORLD_GENERATION":
        return <div className={`${LOADER_CLASS_NAME} text-[#2ae87c]`}>
          <FaGlobe size={20} className="mr-2" />
          <p>{titleCase(category)}</p>
        </div>;
      default:
        return null; // Handle undefined category
    }
  }

  function getLoader(loader) {
    switch (loader.toUpperCase()) {
      case "PAPER":
        return <div className={`${LOADER_CLASS_NAME} text-[#eaa]`}>
          <FaRegPaperPlane size={15} className="mr-2" />
          <p>{titleCase(loader)}</p>
        </div>
      case "BUKKIT":
        return <div className={`${LOADER_CLASS_NAME} text-[#f6af7b]`}>
          <GrBucket size={15} className="mr-2" />
          <p>{titleCase(loader)}</p>
        </div>
      case "FABRIC":
        return <div className={`${LOADER_CLASS_NAME} text-[#dbb69b]`}>
          <LuScroll size={15} className="mr-2" />
          <p>{titleCase(loader)}</p>
        </div>
      case "SPIGOT":
        return <div className={`${LOADER_CLASS_NAME} text-[#f1cc84]`}>
          <SiSpigotmc size={15} className="mr-2" />
          <p>{titleCase(loader)}</p>
        </div>
      case "PURPUR":
        return <div className={`${LOADER_CLASS_NAME} text-[#c3abf7]`}>
          <TbCube size={15} className="mr-2" />
          <p>{titleCase(loader)}</p>
        </div>
    }
  }

  return (
    <div className="flex flex-row items-center justify-center">
      <div className="absolute background-circle min-h-screen top-[-200px] left-12 z-[-1]" />
      <div className="absolute background-circle min-h-screen bottom-[-200px] right-12 z-[-1]" />
      {/* Sidebar */}
      {/* <div className="bg-[#292929] rounded-xl px-5 py-5 w-[17rem]">
        <div className="text-gray-300 flex flex-row items-center justify-center w-max bg-[#3c3c3c]">
          <BiSearch size={25} />
          <input 
            className="bg-transparent focus:outline-none w-max"
            placeholder="Search"
          ></input>
        </div>
      </div> */}
      {/* Content */}
      <div className="ml-5 flex flex-col items-center justify-center mt-10">
        {/* <h1 className="inter-black text-4xl md:text-6xl text-center">
          Plugins
        </h1>
        <p className="text-md md:text-xl max-w-md md:max-w-3xl text-center text-gray-300 mb-3">
          View our catalogue of plugins that can help you out with your events or videos!
        </p> */}
        {/* Grid */}
        {/* Sidebar */}
        <div className="items-start justify-center flex-col lg:flex-row flex lg:mx-32">
          <div className="flex flex-col mr-4">
            <div className="bg-tertiary-color border border-quaternary-color rounded-xl p-5">
              <h1 className="text-2xl">Plugins</h1>
              <p className="text-gray-300">View our catalogue of plugins that can help you out with your events or videos!</p>
            </div>
            {/* Search Box */}
            <div className="flex flex-row w-full items-center justify-center mt-5">
              <div className="text-gray-300 flex flex-row items-center justify-start w-full bg-tertiary-color border border-quaternary-color px-5 py-2 rounded-xl">
                <BiSearch size={25} className="cursor-pointer" onClick={() => setSearch("")} />
                <input
                  className="bg-transparent ml-3 focus:outline-none w-full"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  value={search}
                />
              </div>
            </div>
            {/* Filter Categories */}
            <div className="mt-5 text-gray-300 flex flex-col items-start justify-start w-full bg-tertiary-color border border-quaternary-color px-5 py-5 rounded-xl">
              <p className="text-2xl">Filter by Loader</p>
              {
                LOADERS.map((loader) => {
                  return (
                    <div
                      key={loader}
                      className="flex flex-row items-center justify-start mt-2"
                    >
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(e) => {
                          if (e.target.checked) setLoaderFilter((prev) => [...prev, loader]);
                          else setLoaderFilter((prev) => prev.filter((c) => c !== loader));
                        }}
                      />
                      <p>{titleCase(loader)}</p>
                    </div>
                  );
                })
              }
            </div>
            {/* Filter Categories */}
            <div className="mt-5 text-gray-300 flex flex-col items-start justify-start w-full bg-tertiary-color border border-quaternary-color px-5 py-5 rounded-xl">
              <p className="text-2xl">Filter by Category</p>
              {
                CATEGORIES.map((category) => {
                  return (
                    <div
                      key={category}
                      className="flex flex-row items-center justify-start mt-2"
                    >
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(e) => {
                          if (e.target.checked) setCategoryFilter((prev) => [...prev, category]);
                          else setCategoryFilter((prev) => prev.filter((c) => c !== category));
                        }}
                      />
                      <p>{titleCase(category)}</p>
                    </div>
                  );
                })
              }
            </div>
          </div>
          {/* List */}
          <div className="w-[24rem] md:w-[52rem] lg:w-[80rem]">
            {pluginStatistics.length === 0 && <div className="flex flex-row items-center justify-center ">
              <img className="mt-5" src={Loading} width={50} />
            </div>}
            {pluginStatistics
              .filter((p) => filterSerach(p) && (categoryFilter.length === 0 || p.categories.some(c => categoryFilter.find(x => x.toLowerCase().includes(c.toLowerCase())))) && (loaderFilter.length === 0 || p.loaders.some(l => loaderFilter.find(f => f.toLowerCase().includes(l.toLowerCase())))))
              .map((plugin) => {
                return (
                  <div
                    key={plugin.id}
                    className="rounded-xl bg-tertiary-color border border-quaternary-color px-5 py-5 mx-1 my-1"
                  >
                    <div className="mx-4 flex flex-row items-start justify-between">
                      <div className="flex flex-row items-center justify-center">
                        <img
                          alt="logo"
                          src={plugin.icon_url}
                          width={100}
                          className="rounded-xl w-[8rem] mr-5"
                        />
                        <div>
                          <div className="flex flex-row items-end justify-start">
                            <h1 className="text-2xl mr-2">{plugin.title}</h1>
                            <p className="text-gray-300 text-md">{plugin.version}</p>
                          </div>
                          <p className="text-gray-300 max-w-xl">{plugin.description}</p>
                          <div className="flex flex-row items-center justify-start mt-3">
                            <div className="items-center justify-start text-gray-300 flex flex-row">
                              {(plugin.loaders ?? []).map(loader => getLoader(loader))}
                            </div>
                            <span className="text-gray-600 mr-3">|</span>
                            <div className="items-center justify-start text-gray-300 flex flex-row">
                              {(plugin.categories ?? []).map(category => getCategory(category))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <a
                          rel="noreferrer"
                          href={!plugin.paid ? `${MODRINTH_URL}/${plugin.id}` : `https://lode.gg/shop/${plugin.id}/checkout`}
                          className={`font-extrabold text-black ${plugin.paid ? "bg-red-400" : "bg-green-400"} w-full px-3 py-2 rounded-xl flex items-center justify-center mt-4`}
                        >
                          {plugin.paid ? `Purchase for $${plugin.price}` : "View on Modrinth"}
                        </a>
                        {plugin.wiki_url && (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={plugin.wiki_url}
                            className="font-extrabold text-black bg-orange-400 px-3 py-2 rounded-xl flex items-center justify-center mt-2"
                          >
                            Read Documentation
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
